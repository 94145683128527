@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  min-height: 100%;
}

/* Some things to pull out */

.sss-input {
  @apply pb-3 md:pb-5;
}

.sss-input label {
  @apply block pb-2 font-medium;
}

.sss-input input,
.sss-input textarea,
.sss-input select {
  @apply block w-full border border-gray-300 px-3 py-2 rounded md:rounded-lg;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}
